import backend from "@/api/userPersonas"
import UserPersona from "@/models/UserPersona"
import common from "@/api/common"

let userPersonas = {

    namespaced: true,

    state: {
        personas: {
            data: [],
        },
        persona: new UserPersona(),
        assignedSites: {
            data: [],
        },
        availableSites: {
            data: []
        },
        availableTechnicians: {
            data: []
        },
        assignedTechnicians: {
            data: []
        },
        canAssignPersona: {
            can_assign: false,
            open_jobs: null,
            unknown_sites: []
        },
        transactions: {
            data: [],
        },
        personaList: [],
        addedSites: [],
        removedSites: [],
        addedTechnicians: [],
        removedTechnicians: []
    },

    mutations: {
        setPersonaList: (state, payload) => state.personaList = payload,
        setTransactions: (state, payload) => state.transactions = payload,
        setPersonas: (state, payload) => state.personas = payload,
        clearAssignedSites: (state, payload) => state.assignedSites = payload,
        clearAvailableSites: (state, payload) => state.availableSites = payload,
        clearUserPersona: (state) => {
            state.persona = new UserPersona()
            state.assignedSites = {data:[]}
            state.availableSites = {data:[]}
            state.availableTechnicians = {data: []}
            state.assignedTechnicians = {data: []}
            state.addedSites = []
            state.removedSites = []
            state.addedTechnicians = []
            state.removedTechnicians = []
        },
        name: (state, payload) => state.persona.name = payload,
        sites: (state, payload) => state.persona.sites = payload,
        setAvailableSites: (state, payload) => state.availableSites = payload,
        setAssignedSites: (state, payload) => state.assignedSites = payload,
        setAvailableTechnicians: (state, payload) => state.availableTechnicians = payload,
        setAssignedTechnicians: (state, payload) => state.assignedTechnicians = payload,
        updateList(state, { listName, siteId, action }) {
            const list = state[listName]
            const index = list.indexOf(siteId)
            if (action === 'add' && index === -1) {
                list.push(siteId)
            } else if (action === 'remove' && index !== -1) {
                list.splice(index, 1)
            }
        },
        addSitesToAssignedSites: (state, payload) => {
            state.assignedSites = payload

        },
        addSitesToAvailableSites: (state, payload) => {
            state.availableSites = payload
        },
        removeTechnician: (state, payload) => {
            let index = state.assignedTechnicians.indexOf(payload)
            state.assignedTechnicians.splice(index, 1)
        },
        setPersona: (state, payload) => {
            state.persona = payload
        },
        setPersonaSites: (state, payload) => {
            state.persona.sites = payload
        },
        setCanAssignPersona: (state, payload) => state.canAssignPersona = payload,
    },

    actions: {
        resubmit: ({}, transactionId) => new Promise((resolve, reject) => {
            backend.resubmit(transactionId, ({data}) => {
                resolve(data)
            }, error => reject(error))
        }),
        loadTransactions: ({commit}, path) => new Promise((resolve, reject) => {
            common.loadPath(path, ({data}) => {
                commit("setTransactions", data)
                resolve(data)
            }, error => reject(error))
        }),
        loadPersonas: ({commit}, path) => new Promise((resolve, reject) => {
            common.loadPath(path, ({data}) => {
                commit('setPersonas', data)
                resolve(data)
            }, error => reject(error))
        }),
        loadAvailableSites: ({commit}, { persona, params }) => new Promise((resolve, reject) => {
            backend.loadAvailableSites(persona, params, ({ data }) => {
                commit('setAvailableSites', data)
                resolve(data)
            }, error => reject(error))
        }),
        loadAssignedSites: ({commit}, { persona, params }) => new Promise((resolve, reject) => {
            backend.loadAssignedSites(persona, params, ({ data }) => {
                commit('setPersonaSites', data.data)
                commit('setAssignedSites', data)
                resolve(data)
            }, error => reject(error))
        }),
        loadAllSites: ({commit}, params) => new Promise((resolve, reject) => {
            backend.loadAllSites(params, ({data}) => {
                commit('addSitesToAvailableSites', data)
                resolve()
            }, error => reject(error))
        }),
        availableTechnicians: ({commit}, { persona, params }) => new Promise((resolve, reject) => {
            backend.loadAvailableTechnicians(persona, params, ({data}) => {
                commit('setAvailableTechnicians', data)
                resolve(data)
            }, error => reject(error))
        }),
        assignedTechnicians: ({commit}, { persona, params }) => new Promise((resolve, reject) => {
            backend.loadAssignedTechnicians(persona, params, ({data}) => {
                commit('setAssignedTechnicians', data)
                resolve(data)
            }, error => reject(error))
        }),
        storeAllSites: ({commit}, payload) => new Promise((resolve, reject) => {
            backend.storeAllSitesUserPersona(payload, ({data}) => {
                commit("clearUserPersona")
                resolve(data)
            }, error => reject(error))
        }),
        store: ({commit}, payload) => new Promise((resolve, reject) => {
            backend.storeUserPersona(payload, ({data}) => {
                commit("clearUserPersona")
                resolve(data)
            }, error => reject(error))
        }),
        updateAllSites: ({ commit }, payload) => new Promise((resolve, reject) => {
            backend.UpdateAllSitesUserPersona(payload, ({ data }) => {
                commit('clearUserPersona')
                commit('setPersona',data)
                resolve(data)
            }, error => reject(error))
        }),
        update: ({commit}, payload) => new Promise((resolve, reject) => {
            backend.updateUserPersona(payload, ({data}) => {
                commit("clearUserPersona")
                resolve(data)
            }, error => reject(error))
        }),
        loadPersona: ({commit}, payload) => new Promise((resolve, reject) => {
            backend.getUserPersona(payload, ({data}) => {
                commit("setPersona", data)
                resolve(data)
            }, error => reject(error))
        }),
        validateCanAssignPersona: ({commit}, payload) => new Promise((resolve, reject) => {
            backend.canAssignPersona(payload, ({data}) => {
                commit("setCanAssignPersona", data)
                resolve(data)
            }, error => reject(error))
        }),
        attachPersona: ({}, payload) => new Promise((resolve, reject) => {
            backend.assignPersona(payload, ({data}) => {
                resolve(data)
            }, error => reject(error))
        }),
        searchPersonas: ({ commit }, search) => new Promise((resolve, reject) => {
            backend.searchPersonas(search, ({ data }) => {
                commit('setPersonas', data)
                resolve(data)
            }, error => reject(error))
        }),
        detachPersona: ({}, user) => new Promise((resolve, reject) => {
            backend.detachPersona(user, ({data}) => {
                resolve(data)
            }, error => reject(error))
        }),
        removePersona: ({}, personaId) => new Promise((resolve, reject) => {
            backend.deletePersona(personaId, ({data}) => {
                resolve(data)
            }, error => reject(error))
        }),
    },

    getters: {
        personas: state => state.personas,
        persona: state => state.persona,
        availableSites: state => state.availableSites,
        assignedSites: state => state.assignedSites,
        canAssignPersona: state => state.canAssignPersona,
        assignedTechnicians: state => state.assignedTechnicians,
        availableTechnicians: state => state.availableTechnicians,
        transactions: state => state.transactions,
        addedSites: state => state.addedSites,
        removedSites: state => state.removedSites
    },
}

export default userPersonas